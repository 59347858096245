.guest-table-v2 {
  &--page {
    &__header {
      max-width: 900px;
      margin: 0 auto 2rem auto;
      background-color: $grey_dark;
      color: $white;
      padding: 1rem;
      display: flex;
      align-items: center;
      margin-top: 3rem;
      & .aside {
        margin-left: auto;

        p {
          text-align: right;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-bottom: 8px;
    }


    &__search {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media(min-width: 769px) {
        flex-direction: row;
      }
      button {
        white-space: nowrap;
        flex: 1;
      }
    }
  }
  &--row {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    &__name {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      @media(min-width: 769px) {
        text-align: left;
      }
    }

    &__ticket-types {
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;
    }

    &__ticket-type {
      display: flex;
      flex-direction: column;

      @media(min-width: 769px) {
        align-items: flex-start;
      }

    }
    &__ticket-label {
      color: #333;
      font-size: 12px;
      text-align: center;
      @media(min-width: 769px) {
        //text-align: left;
      }
    }
    &__tickets-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: none;
      gap: 0.5rem;
      @media(min-width: 769px) {
        justify-content: flex-start;
      }
    }
    &__ticket-button {
        position: relative;
        padding: 2px 10px 2px 12px;
        background-color: $green;
        border-color: $green;
      &:not(.loading) {
      cursor: pointer;
      }

        svg {
          position: relative;
          top: 0;
          left: -4px;
          height: 32px;
          width: 32px;
        }

        span {
          position: absolute;
          display: block;
          bottom: 30%;
          right: -8px;
          font-size: 0.7rem;
          font-weight: bold;
          height: 16px;
          width: 16px;
          background-color: $grey_ultra_light;
          color: $grey_medium_dark;
          padding: 0;
          margin: 0;
          border-radius: 16px;
        }


      &.loading {
        cursor: not-allowed;

      }

        &.disabled {
          //opacity: 0.25;
          background-color: $grey_light !important;
          border-color: $grey_light !important;

          &:hover {
            background-color: $grey_dark !important;
          }

          svg {
            color: $grey_medium;
          }
        }
    }
  }
  //background-color: black;
}