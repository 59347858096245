// NAVBAR

.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-color: black;
}

.navbar-structure {
  width: 100%;
  margin: 0;
  text-align: right;
  padding-left: 16px;
  padding-right: 16px;
  background-color: rgba(0,0,0,.95);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-top: -9px;
  padding-top: 8px;
  padding-bottom: 7px;
}

.navbar-toggler {
  padding: 0;
  border: none;
  text-align: right;
  display: inline-block;
  margin-right: 0;
  height: 32px;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}

.SignInUpOutButtons {
  padding: 0;
  border: none;
  text-align: left;
  display: inline-block;
  margin-right: 0;
  height: 32px;
  width: 100%;

  span {
    > div {
      float: left;
      margin-right: .5rem;
    }
  }
}

.userGreeting {
  display: inline-block;
  color: $white;
  line-height: 32px;
  font-size: .875rem;
  text-align: right;
  padding-right: 1rem !important;

  p {
    margin: 0;

    a {
      text-decoration: none;
      color: $grey_light;

      &:hover {
        color: $white;
      }
    }
  }
}

.navbar-brand {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -125px;
  height: 80px;
  width: 250px;
  z-index: 100;

  @include media-breakpoint-down(sm) {
    width: 130px;
    margin-left: -65px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .siteLogo {
      height: 80px;
      width: 250px;
      padding-top: 80px;
      display: block;
      background-color: white;
      overflow: hidden;
      background: no-repeat;
      background-size: contain;
      background-position: center;
      animation: fadeIn 1s ease-in-out; /* Adjust the duration and timing function as needed */
      transition: transform 0.5s ease-in-out;

    @include media-breakpoint-down(sm) {
      width: 130px;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.navbar-nav {
  margin-top: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $grey_dark;
  background-color: rgba(33,37,41,.99);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 95vh;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.8);
  overflow: hidden;

  .nav-item {
    display: block;
    padding: 0 1rem;
    margin-bottom: 0rem;

    &.last {
      margin-bottom: 2rem;
    }

    a {
      font-weight: 300 !important;
      font-size: 1.5rem;
      font-weight: bold;
      padding: .25rem 0;
      text-align: center;
      color: $grey_light !important;
      height: 97.5%;
      transition: all .3s;
      text-decoration: none;

      &:hover {
        color: $white !important;
      }
    }
  }
}

.BloxMenuFooter {
  // position: absolute;
  // bottom: 4rem;
  width: 100vw;
  padding: 1rem;

  ul {
    padding: 0;
    list-style-type: none;
    text-align: center;
    margin-bottom: 1rem;
    li {
      display: inline-block;

      a {
        font-size: .8rem;
        padding: .5rem;
        color: $grey_medium_dark !important;
        transition: all .3s;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          color: $white !important;
          text-decoration: none;
        }
      }
    }
  }

  p {
    color: $white;
    font-size: .8rem;
    font-weight: 200;
    text-align: center;
    color: $grey_medium_dark;
  }
}

.clientAdminContainer {
  max-width: 600px;
}

.BloxClientDashboard {
  background-color: #181A1A;
  padding: .5rem;
  margin-bottom: 2rem;
  margin-top: -3rem;
  padding-top: 1rem;
  height: 14rem;
  overflow: hidden;
  transition: .3s all;
  position: fixed;

  &.collapsed {
    height: 3rem;
  }

  h5 {
    color: $white;
    // text-align: center;
    border-bottom: 1px solid $grey_dark;
    padding-bottom: .5rem;
  }

  h6 {
    color: $grey_base;
    text-transform: uppercase;
    font-size: .8rem;
    margin-bottom: .25rem;
    margin-top: .5rem;
    font-weight: bold;
    // white-space: nowrap;
    // overflow-x: hidden;
    // text-overflow: ellipsis;
  }

  a.nav-link {
    text-decoration: none;
    color: $grey_light !important;
    padding: 0;
    margin-bottom: .25rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: .9rem;

    &:hover {
      color: $white !important;
    }

    svg {
      position: relative;
      top: -2px;
      margin-right: .25rem;
    }
  }
}
