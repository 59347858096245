.alert {
  font-weight: 200;
  margin-top: .5rem;

  &.alert-success {
    background-color: $green !important;
    border-color: $green !important;
    color: $white !important;
    font-weight: 400;
    text-align: center;
  }

  &.alert-danger {
    background-color: $red !important;
    border-color: $red !important;
    color: $white !important;
    font-weight: 400;
    text-align: center;
  }

  &.dismiss{
    position: fixed;
    width: 100vw;
    top: 46px;
    left: -1px;
    z-index: 100;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    padding-left: 3rem;
    padding-right: 1rem;
    border-radius: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-color: transparent !important;
    background-color: rgba(33,37,41,.95) !important;
    color: $white;
    line-height: 1.5rem;
    margin-top: 30px;

    i {
      position: relative;
      top: 4px;
      margin-right: .5rem;
    }

    &.alert-success i {
      color: $green !important;
    }

    &.alert-danger i {
      color: $red !important;
    }
  }
}

.BloxAdminRow {
  .alert {
    &.dismiss {
      left: 0 !important;
      top: 14px !important;
      width: 100%;
    }
  }
}
