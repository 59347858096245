.comp-ticket-modal {
  background: black;
  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > * {
      flex: 1;
    }

    @media(min-width: 769px) {
      flex-direction: row;
      gap: 4px;
    }
  }
}