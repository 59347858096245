.chart-container {
  background-color: $grey_dark;
}

// .frappe-chart text.title {
//   display: none;
// }

/* x axis */
// .frappe-chart .x.axis {
//     display: none;
// }

/* y axis */
// .frappe-chart .y.axis {
//     display: none;
// }

.frappe-chart .y.axis .line-horizontal,
.frappe-chart .y.axis .line-horizontal text {
  display: none;
}

.frappe-chart .y-markers .line-horizontal {
  stroke: $red !important;
  stroke-dasharray: 0 !important;
}

.chartContainer {
  // margin-left: -45px;
  // margin-right: -30px;
}

.eventAnalytics {
  margin-bottom: 1rem;
}

.chart-title {
  margin-bottom: 0.5rem;
  font-size: 11px;
  font-weight: bold;
  color: #222222;
}

.analitics-table-row {
  border-top: 1px solid #cdcdcd;
  margin-top: 10px;
}

.analitics-tickets-type {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}
