
//Tickets Check-in
$color-primary-0: #6D9960;	// Main Primary color */
$color-primary-1: #315B23;
$color-primary-2: #40DC0E;
$color-primary-3: #87DD6C;
$color-primary-4: #2A8C0B;

$color-secondary-1-0: #486770;	// Main Secondary color (1) */
$color-secondary-1-1: #1B3A43;
$color-secondary-1-2: #1086A8;
$color-secondary-1-3: #5698AB;
$color-secondary-1-4: #0B5267;

$color-secondary-2-0: #61517B;	// Main Secondary color (2) */
$color-secondary-2-1: #302049;
$color-secondary-2-2: #571BB5;
$color-secondary-2-3: #8462B8;
$color-secondary-2-4: #371270;

$color-complement-0: #B4A571;	// Main Complement color */
$color-complement-1: #6B5D29;
$color-complement-2: #FFCA10;
$color-complement-3: #FFE27D;
$color-complement-4: #A5830D;

.TicketButton.hvr-grow-shadow.btn-color-1-type-0.btn.btn-primary {
  background-color: $color-primary-1 !important;
  border-color: $color-primary-1 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-2-type-0.btn.btn-primary {
  background-color: $color-primary-2 !important;
  border-color: $color-primary-2 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-3-type-0.btn.btn-primary {
  background-color: $color-primary-3 !important;
  border-color: $color-primary-3 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-4-type-0.btn.btn-primary {
  background-color: $color-primary-4 !important;
  border-color: $color-primary-4 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-1-type-1.btn.btn-primary {
  background-color: $color-secondary-1-1 !important;
  border-color: $color-secondary-1-1 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-2-type-1.btn.btn-primary {
  background-color: $color-secondary-1-2 !important;
  border-color: $color-secondary-1-2 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-3-type-1.btn.btn-primary {
  background-color: $color-secondary-1-3 !important;
  border-color: $color-secondary-1-3 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-4-type-1.btn.btn-primary {
  background-color: $color-secondary-1-4 !important;
  border-color: $color-secondary-1-4 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-1-type-2.btn.btn-primary {
  background-color: $color-secondary-2-1 !important;
  border-color: $color-secondary-2-1 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-2-type-2.btn.btn-primary {
  background-color: $color-secondary-2-2 !important;
  border-color: $color-secondary-2-2 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-3-type-2.btn.btn-primary {
  background-color: $color-secondary-2-3 !important;
  border-color: $color-secondary-2-3 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-4-type-2.btn.btn-primary {
  background-color: $color-secondary-2-4 !important;
  border-color: $color-secondary-2-4 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-1-type-3.btn.btn-primary {
  background-color: $color-complement-1 !important;
  border-color: $color-complement-1 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-2-type-3.btn.btn-primary {
  background-color: $color-complement-2 !important;
  border-color: $color-complement-2 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-3-type-3.btn.btn-primary {
  background-color: $color-complement-3 !important;
  border-color: $color-complement-3 !important;
}

.TicketButton.hvr-grow-shadow.btn-color-4-type-3.btn.btn-primary {
  background-color: $color-complement-4 !important;
  border-color: $color-complement-4 !important;
}