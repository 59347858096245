@import 'Calendar';
@import 'Carousel';
@import 'Details';
@import 'Purchase';
@import 'Tile';
@import 'GuestListv2';
@import 'CompTicketModal';


.BloxEventsRow {
  height: 100vh;
  // margin-left: -1rem;
  // margin-right: -1rem;
  background-color: $black;

  > .col {
    height: 100%;
  }
}

.BloxEventsHeroImageColumn {
  background-color: $black;
  width: 100%;
  height: 100vh !important;
  padding-top: 2rem;
  padding-bottom: 2rem;

  button.TapOrScrollForMore {
    bottom: 4rem;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.BloxEventsDetailsColumnPurchaseReceipt,
.BloxEventPurchasePage {
  @include media-breakpoint-down(sm) {
    padding-top: 4.5rem;
  }
}


.eventHeroImage {
  background-color: $black;
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 2rem 3rem;

  width: 50% !important;
  height: 100vh !important;
  position: fixed !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    position: relative !important;
  }

  div {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}