table {
  position: relative;
  min-height: 4rem;

  h5 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 1rem 0 1rem 0;
    border-bottom: 1px solid $grey_very_light;
    color: $black;
  }

  th,
  td {
    text-align: left;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.2;

    &.left-align {
      text-align: left;
    }

    &.purchase-row {
      padding: 0.3rem 0 1.5rem !important;
    }

    &.right-align {
      text-align: right;
    }

    &.center-align {
      text-align: center;
    }

    &.order-number span {
      display: inline-block;
      background-color: $grey_medium;
      color: $white;
      font-weight: bold;
      padding: 0 1rem;
      text-transform: uppercase;
      border-radius: 20px;
      min-width: 110px;
      text-align: center;
    }

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;

      .btn {
        margin-right: 0.25rem !important;
      }
    }

    span.description {
      display: block;
      color: $grey_medium;
      font-size: 0.8rem;
      line-height: 1rem;
      text-transform: none;
      font-weight: 300;
      margin-bottom: 0.25rem;

      p {
        margin-bottom: 0.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.inventoryDetails,
    span.description {
      span {
        display: block;
        color: $grey_medium;
        font-size: 0.8rem;
        line-height: 1rem;
        text-transform: none;
        font-weight: 300;

        p {
          margin-bottom: 0.25rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  th {
    border-color: $grey_very_light !important;
    color: $grey_medium;
    border-bottom-width: 1px !important;
    border-top-width: 0 !important;
    font-size: 1rem;
    line-height: 1rem;
    padding-top: 0 !important;
    padding-bottom: 4px !important;
    text-transform: lowercase;
    font-variant: small-caps;

    &.purchase-header{
      padding: 0 !important;
    }
  }

  td {
    line-height: 24px;

    i.material-icons {
      float: right;
      color: $grey_light;
      line-height: 24px;
    }

    input#quantity {
      float: right;
      display: inline-block !important;
      width: 2.5rem !important;
      text-align: center;
      margin-left: 6px;
      height: 24px;
      padding: 0rem;
      font-size: 1rem;
      line-height: 24px;
    }
  }

  tfoot {
    tr {
      border-top: 0 !important;
      &:first-child {
        border-top: 1px solid $grey_very_light !important;
      }
    }

    td,
    th {
      border-top-width: 0 !important;
      font-size: 1rem;

      &.subtotal {
        text-transform: none;
        border-top: 1px solid $grey_light !important;
        font-size: 1rem;
        line-height: 1rem;
        // background-color: $grey_ultra_light;
        font-weight: 400;
        padding-top: 0.75rem !important;
        padding-bottom: 0.4rem !important;
      }

      &.fee {
        text-transform: none;
        border-top: none !important;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 300;
        color: $grey_medium;
        padding-top: 0.4rem !important;
        padding-bottom: 1rem !important;
      }

      &.total {
        padding-top: 0.75rem !important;
        text-transform: uppercase;
        border-top: 2px solid $grey_base !important;
        font-size: 1.25rem;
      }
    }
  }
}

.guestTableCell {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.tableDataCount {
  font-weight: bold;
  color: $grey_medium;
  float: right;
  height: 40px;
  line-height: 40px;
}

.tableHeader {
  font-weight: bold;
  color: $white;
  background: #333333;
  width: 100%;
  padding: 10px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .tableHeader-Name {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

table {
  width: 100%;

  th,
  td {
    border: none !important;
    margin: 0;
    padding: 0 !important;
    margin: 0;
    line-height: 1.25rem;
    text-align: right;
  }

  th {
    padding-right: 1rem !important;
    text-align: left;
  }

  tr.total-table-subtotal-row {
    td,
    th {
      padding-bottom: 0.5rem !important;
      border-top: 1px solid $grey_very_light !important;
      padding-top: 0.75rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1rem;
      text-transform: none;
      color: $black;
    }
  }

  tr.total-table-fee-row {
    td,
    td {
      font-size: 1rem;
      color: $grey_medium;
      padding-bottom: 0.5rem !important;
      font-weight: 400;
      text-transform: none;
    }
  }

  tr.total-table-total-row {
    td,
    th {
      color: black;
      font-weight: bold;
      font-size: 1.25rem;
      padding-top: 0.25rem !important;
      border-top: 2px solid $grey_very_dark !important;
    }
  }
}

.tickets-table {
  .qty-name,
  .price-name {
    text-align: right;
    padding-right: 0 !important;
  }
  tbody {
    border-top: 2px solid $grey_very_light !important;

    .tickets-type {
      text-align: left;
    }
  }
}

.react-bootstrap-table {
  th.sortable {
    // text-decoration: underline;
    cursor: default;
    transition: 0.3s all;

    &:nth-child(2) {
      text-align: end;
    }

    &:nth-child(3) {
      text-align: center;
    }

    &:hover {
      color: $grey_base;
    }

    .caret {
      display: none;
      // display: inline-block;
      height: 8px;
      width: 8px;
    }

    .dropdown .caret {
      background-color: $red;
    }

    .dropup .caret {
      background-color: $green;
    }

    .react-bootstrap-table-sort-order .caret {
      display: inline-block;
      background-color: $green;
      border-radius: 8px;
      margin-left: 0.25rem;
    }
  }
  tbody {
    tr {
      &:hover {
        background: rgb(179, 205, 252);
      }
    }
  }
  td {
    text-transform: none;
    font-weight: normal;
    line-height: 1.25rem;

    span {
      font-size: 1rem;
      line-height: 1.25rem;
      display: block;

      svg {
        position: relative;
        top: -1px;
      }

      &.quiet {
        font-size: 0.8rem;
        color: $grey_medium;
      }

      &.success {
        svg {
          color: $green;
        }
      }
    }

    &.name-col {
      font-weight: bold;
      text-transform: uppercase;
      text-align: left !important;
    }

    &.order-col {
      cursor: pointer;
      text-align: left !important;
    }

    &.registered-col {
      font-weight: bold;
      text-transform: uppercase;
      text-align: end;
    }

    &.last-activity-col {
      font-weight: bold;
      text-align: center;
    }

    &.event-col {
      max-width: 220px;

      .eventTile {
        width: 100%;
      }
    }

    &.order-number-col {
      &:hover {
        text-decoration: underline !important;
      }
      color: #007bff !important;
      cursor: pointer !important;
    }

    &.tickets-col {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 150px;

        li {
          margin: 0 0 1rem 0;
          padding: 0 0 0 1.5rem;
          position: relative;
          width: 150px;
          display: block;

          svg {
            position: relative;
            top: -1px;
            margin-left: -1.25rem;
          }

          span {
            color: $grey_medium;
            display: block;
            font-size: 1rem;
            position: absolute;
            left: 1.75rem;
            top: 0;
            width: 120px;

            strong {
              color: $grey_base;
            }
          }
        }
      }
    }

    &.user-col {
      // max-width: 140px;

      h5 {
        margin: 0;
        padding: 0;
        border-bottom: none;
        font-size: 1rem;
        line-height: 1.25rem;
      }
      h6 {
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
      }
    }

    &.total-col {
      max-width: 145px;
      padding-right: 0.5rem;

      table {
        max-width: 145px;

        th,
        td {
          border: none !important;
          margin: 0;
          padding: 0 !important;
          margin: 0;
          line-height: 1.25rem;
          text-align: right;
        }

        th {
          padding-right: 1rem !important;
        }

        tr.total-table-fee-row {
          td {
            font-size: 0.8rem;
            color: $grey_medium;
            padding-bottom: 0.5rem !important;
          }
        }

        tr.total-table-total-row {
          td,
          th {
            font-weight: bold;
            padding-top: 0.25rem !important;
            border-top: 1px solid $grey_very_light !important;
          }
        }
      }
    }

    &.map-col {
      width: 200px;
    }
  }
}

.pagination {
  justify-content: center !important;

  li {
    a {
      text-decoration: none;
      border-color: $grey_very_light !important;
      color: $grey_medium;

      svg {
        position: relative;
        top: -1px;
      }

      &:hover {
        background-color: $grey_very_light !important;
        border-color: $grey_very_light !important;
        color: $grey_medium;
      }
    }

    &.active {
      a {
        color: $white;
        background-color: $grey_base !important;
        border-color: $grey_base !important;
      }
    }
  }
}

.adminTicketInventoryTable {
  // .price,
  // .quantity,
  .start-date,
  .end-date {
    display: none;
    @include media-breakpoint-up(sm) {
      display: table-cell;
    }
  }
}
