.modal {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: all .3s;
  background-color: rgba(0,0,0,.5);
}

.modal-dialog {
  margin-top: 3rem;
}

.modal-content {
  background-color: $grey_dark;
}

.modal-header {
  background-color: $grey_dark;
  border: none;
  border-bottom: 1px solid $grey_very_light;

  button {
    span {
      color: $white;
    }
  }
}

.modal-title {
  color: $white;
  div {
    display: inline-block;
    margin-left: .5rem;
    position: relative;
    top: -1px;
  }
}

.modal-body {
  background-color: $white;
  padding: .5rem;

  form {
    margin-top: 0;
    margin-bottom: 0;
  }
}
