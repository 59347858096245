// .BloxEventCarousel {
//   background-color: $black;
//   width: 100%;
//   height: 100vh !important;
//   padding-top: 2rem;
//   padding-bottom: 2rem;
//
//   a.carousel-control-next,
//   a.carousel-control-prev {
//     width: 7.5% !important;
//   }
//
//   .carousel-indicators {
//     margin: 0;
//     padding: 0;
//     top: auto !important;
//     bottom: 2.75rem !important;
//
//     @include media-breakpoint-down(md) {
//       top: 7rem !important;
//       bottom: auto !important;
//     }
//   }
//
//   div.carousel-inner {
//     margin-left: 7.5%;
//     margin-right: 7.5%;
//     width: 85%;
//     height: 100% !important;
//     opacity: 0;
//     transition: all 1s;
//   }
//
//   div.carousel-item  {
//     width: 100%;
//     height: 100%;
//
//     div {
//       display: block;
//       width: 100%;
//       height: 100% !important;
//       background-position: center center;
//       background-size: contain;
//       background-repeat: no-repeat;
//     }
//   }
// }

.BloxEventsCarouselColumn {
  background-color: $black;
  width: 100%;
  height: 100vmax !important;
  padding-top: 0;
  padding-bottom: 0;
}

.carousel-status {
  display: none;
}


.carousel {
  width: 50% !important;
  height: 100vh !important;
  padding-top: 2rem;
  touch-action: pan-x;
  position: fixed !important;
  background-color: $black;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    position: relative !important;
  }

  .slider-wrapper {
    width: 100%;
    height: 100%;

    .slider {
      width: 100%;
      height: 100%;
    }
  }

  .slide {
    width: 100%;
    height: 100%;

    .eventCarouselItem {
      width: 100%;
      height: 100%;
      display: block;
      padding: 1rem 1.5rem 5rem 1.5rem;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-origin: content-box;

      @include media-breakpoint-down(md) {
        padding: 5.5rem 1.5rem 10rem 1.5rem;
      }

      img {
        display: none;
      }
    }

    div.eventCarouselItemGlitch {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      div {
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .control-arrow {
    padding: 5px 5px 5px 40px !important;

    &.control-prev {
      padding: 5px 40px 5px 5px !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .control-dots {
    margin: 0;
    padding: 0;
    bottom: 3.5rem;

    @include media-breakpoint-down(md) {
      top: 5.5rem !important;
      bottom: auto !important;
    }
  }

  .dot {
    margin: 0 2px !important;
  }
}

.noEventsLogo {
  width: 75%;
  height: 75%;
  margin: 12.5%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
}
.noEventsMessage {
  width: 75%;
  height: 75%;
  margin: 12.5%;

  h1 {
    font-weight: bold;
    text-transform: none;

    span {
      display: block;
      color: $grey_medium;
      text-transform: none;
      font-weight: 200;
    }
  }
}
