.fanCampaignQuestion {
  margin: 0 0;
  opacity: .1;
  transition: .3s all ease-in-out;

  &.animate-in {
    opacity: 1;
  }

  &.animate-out {
    opacity: .1;
  }

  .fanCampaignQuestionQuestion {
    h3 {
      text-align: center;
      font-weight: bold;
    }
  }

  .fanCampaignQuestionChoice {
    padding: .5rem;

    h4 {
      width: 100%;
      margin-left: -.5rem;
      margin-right: -.5rem;
      text-align: center;
      position: absolute;
      top: 60px;
      text-transform: uppercase;
      color: $grey_medium_dark;
    }

    > div {
      min-height: 80px;
      max-height: 180px;
      height: 100%;
      width: 100%;
      margin: 110px auto;
      text-align: center;
    }

    &.fanCampaignQuestionChoice-1 {
    }

    &.fanCampaignQuestionChoice-2 {
    }

    div.ddcontainer {
      min-height: 80px;
      max-height: 180px;
      height: 100%;
      margin: 0;
      opacity: 1;
      transition: .3s all;

      &.chosen {
        opacity: 0;
      }

      span.ddcontainersource,
      div.ddcontainerghost {
        color: $white;
        min-height: 80px;
        max-height: 180px;
        height: 100%;
        width: 180px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
          width: 80px;
        }

        h5 {
          min-height: 80px;
          max-height: 180px;
          height: 100%;
          padding-top: 180px;
          overflow: hidden;
          margin: 0 auto;
          text-align: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }


        div {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          min-height: 80px;
          max-height: 180px;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .fanCampaignQuestionChoiceTarget {
    padding: .5rem;

    span.droptarget {
      display: block;
      min-height: 180px;
      max-height: 400px;
      height: 100%;
      max-width: 400px;
      margin: 0 auto;
    }

    div {
      min-height: 180px;
      max-height: 400px;
      height: 100%;
      max-width: 400px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.fanCampaignQuestionMobile {
  margin: 0 0;
  opacity: .1;
  transition: .3s all ease-in-out;

  &.animate-in {
    opacity: 1;
  }

  &.animate-out {
    opacity: .1;
  }

  .fanCampaignQuestionQuestion {
    h3 {
      text-align: center;
      font-weight: bold;
    }
  }

  .fanCampaignQuestionChoice {
    padding: .5rem;

    h4 {
      width: 100%;
      margin-left: -.5rem;
      margin-right: -.5rem;
      text-align: center;
      position: relative;
      top: 60px;
      text-transform: uppercase;
      color: $grey_medium_dark;
    }

    > div {
      min-height: 80px;
      max-height: 180px;
      height: 100%;
      width: 100%;
      margin: 110px auto;
      text-align: center;
    }

    &.fanCampaignQuestionChoice-1 {
    }

    &.fanCampaignQuestionChoice-2 {
    }

    div.ddcontainer {
      min-height: 80px;
      max-height: 180px;
      height: 100%;
      margin: 0;
      opacity: 1;
      transition: .3s all;

      &.chosen {
        opacity: 0;
      }

      span.ddcontainersource,
      div.ddcontainerghost {
        color: $white;
        min-height: 80px;
        max-height: 180px;
        height: 100%;
        width: 180px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
          width: 80px;
        }

        h5 {
          min-height: 80px;
          max-height: 180px;
          height: 100%;
          padding-top: 180px;
          overflow: hidden;
          margin: 0 auto;
          text-align: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }


        div {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          min-height: 80px;
          max-height: 180px;
          height: 100%;
          width: 100%;
          position: relative;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .fanCampaignQuestionChoiceTarget {
    padding: .5rem;

    span.droptarget {
      display: block;
      min-height: 180px;
      max-height: 400px;
      height: 100%;
      max-width: 400px;
      margin: 0 auto;
    }

    div {
      min-height: 180px;
      max-height: 400px;
      height: 100%;
      max-width: 400px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

