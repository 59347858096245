.BloxEventsDetailsColumn {
  // overflow-x: scroll;
  background-color: $white;

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    min-height: 95%;
  }

  &.loading-true {
    background-color: $black;
  }

  @include media-breakpoint-up(md) {
    padding-top: 1.85rem;
    padding-top: 5.5rem;
  }

  &.BloxEventsDetailsColumnPurchaseReceipt {
    @include media-breakpoint-up(md) {
      padding-top: 1.85rem;
    }
  }

  .BloxEventPurchase {
    transform: scaleY(0);
    transform-origin: bottom;
  }

  // Transitions upon carousel slide
  &.sliding-true {
    .BloxEventDetailsHeader,
    .BloxEventDetailsContent,
    .EventDetailsTablePurchaseRow {
      transition: all 0.3s ease-in-out;
      opacity: 0.5;
    }

    .BloxEventPurchase {
      transform: scaleY(0);
      transform-origin: bottom;
    }
  }

  &.sliding-false {
    .BloxEventDetailsHeader,
    .BloxEventDetailsContent,
    .EventDetailsTablePurchaseRow {
      transition: all 0.3s ease-in-out;
      opacity: 1;
      // max-height: 330px;
      // overflow-x: auto;
    }

    .BloxEventPurchase {
      transform: scaleY(1);
      transform-origin: bottom;
    }
  }

  @include media-breakpoint-up(md) {
    // padding-bottom: 226px;
  }
}

.event-details-outer-container{  
    @include media-breakpoint-down(md) {
      height: 100%;
      .event-details-container{
        height: 100%;
      }
      .event-details-inner-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  
}

.BloxEventDetails {
  opacity: 0;
  transition: all 1.5s;
  @include media-breakpoint-down(md) {
    padding-bottom: 0 !important;
  }
  > .row {
    margin: 0;
    margin-bottom: 0.5rem;
    // padding: 0 1rem;
  }
}

.BloxEventDetailsHeader {
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    padding-top: 0.45rem;
    line-height: 1.75rem;
    color: $grey_medium;
    font-weight: 600;
  }
}

.BloxEventDetailsContent {
  &.BloxEventDescription {
    padding-bottom: 4%;
  }

  h4 {
    color: $grey_dark;
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 0.25rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  h5 {
    color: $grey_medium;
    font-weight: 400;
  }

  span {
    display: inline;
    float: none;
    font-size: inherit;
    font-style: normal;

    &.time2 {
      display: block;
    }

    &.timeLabel {
      text-transform: normal;
      color: $grey_medium;
      margin-left: 0.15rem;
      font-size: 1.2rem;
      text-transform: none;
      font-weight: 400;
    }

    &.timeData {
      text-transform: lowercase;
    }
  }

  p {
    margin-top: 0;

    @include media-breakpoint-up(med) {
      margin-top: 0.5rem;
    }
  }
}

.TapOrScrollForMore {
  position: relative;
  bottom: 8rem;
  left: 25%;
  color: $white;
  text-align: center;
  width: 50%;
  font-size: 0.8rem;
  text-transform: uppercase;
  opacity: 1;
  transition: all 0.6s;
  font-weight: bold;

  &:hover {
    color: $white;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.eventDetailsLink {
  color: $grey_base;
  height: 90%;
  width: 48px;
  margin: 0 auto;
  margin-left: 10%;
  margin-right: 10%;
  // background-color: $white;
  position: absolute;
  top: 2rem;
  left: 0;
  width: 80%;
  text-align: center;
  z-index: 80;
}

table {
  .recent-orders-table-row {
    border-top: 1px solid #cecece;
  }
  th {
    &.recent-orders-details-name {
      text-align: end;
      padding: 0 !important;
      padding-right: 10px !important;
    }
    &.recent-orders-time-name {
      text-align: center;
      padding: 0 !important;
      padding-right: 10px !important;
    }
    &.recent-orders-purchaser-name {
      text-align: center;
      padding: 0 !important;
      padding-right: 10px !important;
    }
    &.recent-orders-total-name {
      text-align: end;
      padding: 0 !important;
      padding-right: 10px !important;
    }
    &.recent-orders-actions-name {
      text-align: center;
      padding: 0 !important;
      padding-right: 10px !important;
    }
  }

  td {
    &.recent-orders-details {
      text-align: start;
      padding-right: 10px !important;
      white-space: nowrap;
    }
    .name-and-qty {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      @media (max-width: 450px) {
        width: 110px;
      }
      @media (max-width: 425px) {
        width: 60px;
      }
      @media (max-width: 385px) {
        width: 20px;
      }
      @media (max-width: 345px) {
        width: 20px;
      }
    }

    .secondary-span {
      color: #99abaa;
      font-size: 0.8rem;
      line-height: 1rem;
      text-transform: none;
      font-weight: 300;
      margin-bottom: 0.25rem;
      &.status {
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 450px) {
          width: 110px;
        }
        @media (max-width: 425px) {
          width: 60px;
        }
        @media (max-width: 385px) {
          width: 20px;
        }
        @media (max-width: 345px) {
          width: 20px;
        }
      }
    }
    &.recent-orders-time {
      text-align: center;
      // font-size: 11px;
      text-transform: none;
      padding-right: 10px !important;
    }
    &.recent-orders-purchaser {
      text-align: center;
      padding-right: 10px !important;
    }
    &.recent-orders-total {
      text-align: end;
      padding: 0 !important;
      padding-right: 10px !important;
    }
    &.recent-orders-actions {
      text-align: center;
      padding-right: 10px !important;
    }
  }
}

table {
  th {
    &.subpromotors-actions-name {
      text-align: end;
    }
    &.subpromotors-views-name {
      text-align: end;
      padding: 0 !important;
    }
    &.subpromotors-tickets-name {
      text-align: start;
    }
    &.subpromotors-links-name {
      text-align: start;
    }
  }

  td {
    &.subpromotor-details {
      text-align: start;
      padding-bottom: 15px !important;
      padding-top: 15px !important;
    }
    &.subpromotor-link {
      text-align: start;
      font-size: 11px;
      text-transform: none;
    }
    &.subpromotor-view {
      text-align: end;
    }
    &.subpromotor-actions {
      text-align: end;
    }
  }
}
