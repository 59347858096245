// Forms
form {
  background-color: $grey_ultra_light;
  padding: 0.5rem;
  padding-top: 0;
  border-radius: 5px;
  max-width: 900px;
  margin: 1rem auto 1rem auto;

  h4.Header {
    text-transform: none;
    background-color: $grey_dark;
    color: $grey_ultra_light;
    margin: 1.5rem -0.5rem 0.5rem -0.5rem;
    // font-weight: 400 !important;
    text-transform: uppercase;
    padding: 0.5rem;
    font-size: 1.25rem;
    border-bottom: 1px solid $grey_very_light;
  }

  > h4.Header {
    &:first-child {
      margin-top: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  input.form-control,
  textarea.form-control {
    border: 3px solid $grey_light;
    color: $grey_base;
    border-radius: 5px;
    transition: 0.3s all;
    padding: 0.25rem 0.5rem !important;
    height: 42px !important;
    line-height: 1.95 !important;
    // box-shadow: inset 0 1px 0 0 $grey_extra_light;
  }

  textarea.form-control {
    height: 126px !important;
  }

  input:focus,
  textarea:focus,
  input.rw-widget-input:focus {
    border-color: $grey_base !important;
    outline: 0px !important;
    -webkit-appearance: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  input[type="file"] {
    background-color: $white;
    border: 3px solid $grey_light;
    height: 39px;
    border-radius: 5px;
    transition: 0.3s all;
    padding: 2px;
    height: 42px !important;
    line-height: 1.7 !important;
  }

  input[type="checkbox"]:focus {
    -webkit-appearance: checkbox !important;
    -webkit-box-shadow: checkbox !important;
  }

  label {
    font-weight: bold;
    font-size: 0.75rem;
    width: 100%;
    color: $grey_base;
    // display: none;
    text-align: left;
    text-transform: uppercase;
    font-variant: small-caps;
    margin-bottom: 0;

    &.form-check-label {
      text-transform: none;
      font-size: 1rem;
      font-variant: none;
      font-weight: 400;
    }

    span {
      display: inline-block;
      text-transform: none;
      &.required {
        color: $red;
        margin-left: 0.15rem;
      }
      &.optional {
        float: right;
        color: $grey_medium;
        text-transform: lowercase;
      }
    }
  }

  .invalid {
    input {
      border: 3px solid $red;
    }

    .rw-widget-container {
      border: 3px solid $red !important;

      input {
        border: none;
      }
    }
  }

  .error,
  #card-errors {
    color: $red;
    text-align: right;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-transform: lowercase;
    font-variant: small-caps;

    &.inactive {
      display: none;
    }
  }

  #card-errors {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .form-control::placeholder,
  .rw-widget-input::placeholder,
  .rw-input-reset::placeholder,
  .rw-placeholder {
    color: $grey_light;
    opacity: 1;
  }

  .form-row {
    margin-left: 0;
    margin-right: 0;
  }

  .form-group {
    padding-left: 0;
    margin-bottom: 0;

    &.last {
      margin-bottom: 0;
    }
  }

  .form-check {
    margin: 0 0 1rem 0;
  }

  .buttonRow {
    margin-top: 1rem;
  }
}
.ticketRow {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  align-items: center;
}

.ticketRowHeader {
  border-bottom: 1px solid #dedede;
}

.soldAndQuantity{
  display: flex;
  justify-content: center;

  .totalSold {
    font-size: 0.85rem;
    color: #646464;
  }
}

#card-element {
  padding: 0.5rem;
  background-color: $white;
  border: 3px solid $grey_light;
  color: $grey_base;
  border-radius: 5px;
  transition: 0.3s all;
}

.rw-widget-input {
  box-shadow: none !important;
  padding: 0.25rem 0.5rem !important;
}

.rw-dropdown-list-input {
  padding: 0 !important;
}

.rw-widget-container {
  border: 3px solid $grey_light !important;
  padding: 0 !important;

  .rw-btn {
    background-color: $grey_light !important;
    border-color: $grey_light !important;
    width: 36px !important;
    height: 36px !important;
    display: block !important;
    position: relative;
    top: 0;

    &:hover {
      background-color: $grey_medium !important;
      border-color: $grey_medium !important;
    }
  }
}

.rw-multiselect-tag .rw-btn-select.rw-btn {
  background-color: $grey_base !important;
  border-color: $grey_base !important;
  width: 24px !important;
  height: 28px !important;
  line-height: 16px !important;
  display: block !important;
  position: relative;
  top: -1px;

  &:hover {
    background-color: $grey_base !important;
    border-color: $grey_base !important;
  }
}

.rw-calendar-popup {
  .rw-btn {
    background-color: $grey_light !important;
    width: 100% !important;
    height: auto !important;
    display: inline-block !important;
    position: relative !important;

    &.rw-calendar-btn-view {
      width: 75% !important;
    }

    &.rw-calendar-btn-left {
      width: 12.5% !important;
    }

    &.rw-calendar-btn-right {
      width: 12.5% !important;
    }
  }
}

.rw-input {
  padding: 0.25rem 0.5rem !important;
}

.rw-state-focus {
  .rw-widget-container {
    border-color: $grey_base !important;
    outline: 0px !important;
    -webkit-appearance: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.rw-list-option.rw-state-focus,
.rw-list-option.rw-state-focus:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $grey_base !important;
}
.rw-list-option.rw-state-focus {
  background-color: $grey_very_light !important;
}

.rw-popup-container {
  margin-top: -3px !important;
}

.rw-popup {
  box-shadow: none !important;
  border-width: 3px !important;
  border-color: $grey_base !important;
}

.rw-btn {
  // background-color: $grey_light !important;
  // width: 36px !important;
  // height: 36px !important;
}

.rw-multiselect {
  height: 135px !important;

  &[name="allowed_promo_codes"],
  &[name="primary_artists"],
  &[name="secondary_artists"],
  &[name="primary_promoters"],
  &[name="secondary_promoters"],
  &[name="resident_artists"] {
    height: 144px !important;

    .rw-widget-input {
      height: 144px !important;
    }

    .rw-widget-picker {
      height: 144px !important;
    }

    &.rw-open {
      .rw-widget-picker {
        height: 166px !important;

        > div {
          height: 0 !important;
          display: block;
          top: -1px;
        }
      }
    }
  }

  .rw-widget-picker {
    height: 135px !important;

    > div {
      height: 0 !important;
      display: block;
      top: -12px;
    }
  }

  .rw-btn {
    background-color: $grey_light !important;
    width: 36px !important;
    height: 36px !important;
    display: block !important;
    position: relative;
    top: 0;
  }

  &.rw-open {
    .rw-widget-picker {
      height: 36px !important;

      > div {
        height: 0 !important;
        display: block;
        top: -1px;
      }
    }
  }

  .rw-popup-container {
    margin-top: 3px !important;
  }
}

.rw-multiselect-tag {
  height: 32px !important;
  background-color: $grey_base !important;
  border: none !important;
  color: $white !important;
  position: relative !important;
  top: -1px !important;

  .rw-btn {
    background-color: transparent !important;
    width: 24px !important;
    height: 32px !important;
  }
}

.form-text {
  line-height: 1.2;
  color: $grey_medium !important;

  div {
    display: inline-block;
  }

  button {
    display: inline-block;
    margin-left: 0.25rem;
  }
}

.BloxAdminRow form {
}

.formDescription {
}

.promoCodeForm {
  .btn {
    margin-top: 1.6rem;
  }
}

.DateTimePicker {
  .rw-select {
    width: 73px !important;
    height: 36px !important;
    display: table-cell;
  }
  .rw-btn {
    display: inline-block !important;
  }
}

.col label + button {
  position: relative;
  top: 2px;
}

.promoCodeTicketInventoriesForm {
  .row {
    margin-bottom: 1rem;

    .form-check {
      margin-bottom: 0.5rem;
    }

    h5 {
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid $grey_very_light;
    }
  }
}

.currentEventImage {
  img {
    height: 200px;
  }
}

.currentEventImage {
  img {
    height: 75px;
  }
}

.venue-undisclosed-checkBox{
  margin-top: 1.25rem;
  @media screen and (max-width: 535px) {
    margin-top: 0;
    justify-content: flex-start;
  }
}

.nocknock-ad-link{
  margin-top: 1.25rem;
  color: $grey_base;
  img {
    width: 120px;
    height: 20px;
  }
  @media screen and (max-width: 535px) {
    margin-top: 0rem;
    justify-content: flex-start;
  }
}

.venue-undisclosed-checkBox> :first-child {
  width: 75%;
  @media screen and (max-width: 900px) {
    width: 65%;
  }
  @media screen and (max-width: 665px) {
    width: 55%;
  }
  @media screen and (max-width: 535px) {
    width: 100%;
  }
}

.EventProviderAgreementForm {
  .form-check {
    padding: 1rem 2rem 0 2rem !important;
  }
}

.select-border {
  border: 3px solid #bac9c8;
  border-radius: 5px;
}
