// .card-columns {
//   column-count: 4;
// }

.card-deck {
  > div {
    display: inline-block;
  }
}

.card {
  width: 23%;
  min-width: 200px;
  margin: 1%;
  border: none;
  border-radius: 5px !important;
  transition: .3s all;

  @include media-breakpoint-down(sm) {
    width: 95%;
    margin: .5rem 2.5% .5rem 2.5%;
  }

  &:hover {
    .card-footer {
      background-color: $black;
    }

    .cardImage {
      filter: none;
    }
  }

  &.ArtistTile {

    .cardImage {
      height: 250px;
      background-position: top;
      background-size: cover;
      background-color: $black;
    }
    .card-footer {
      height: auto;
    }
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    border-radius: 0;
  }

  .cardImage {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-color: $black;
    filter: grayscale(25%);
    transition: .3s all;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: 300px;
    }

    svg {
      margin-top: 2rem;
      font-size: 8rem;
      color: $white !important;
    }
  }

  .card-img-overlay {
    padding: 0;

    .BloxChart {
      position: absolute;
      top: .25rem;
      width: 6rem;
      height: 3rem;
      margin-left: -.5rem;
      margin-right: -.5rem;

      canvas {
        margin-left: -.75rem;
      }
    }
  }

  .card-footer {
    height: 4rem;
    padding: .25rem .5rem .5rem .5rem;
    background-color: $grey_dark;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: .3s all;

    span {
      line-height: 1.25;
      display: block;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span.title {
      svg {
        position: relative;
        top: -2px;
        margin-right: .15rem;
        color: $grey_medium;
      }
    }

    span.date,
    span.venue {
      color: $grey_medium;
      text-transform: none;
      font-weight: 300;
      font-size: .75rem;
      //margin-right: 50%;
    }

    div.description {
      color: $grey_very_light;
      font-size: .75rem;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .cardIcons {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 108px;
      height: 2rem;
      padding-top: 1px;
      padding-right: .5rem;
      text-align: center;

      a {
        display: block;
        float: right;
        padding: 0 .25rem;
      }

      svg {
        margin: 0 auto;
        color: $grey_light;
        transition: .3s all;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
