.btn {

  svg {
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    top: -2px;
  }

  &.btn-primary {
    background-color: $grey_base;
    border-color: $grey_base;

    &:hover {
      background-color: $grey_dark;
      border-color: $grey_dark;
    }

    &:active {
      background-color: $black !important;
      border-color: $black !important;
    }

    &.disabled {
      background-color: $grey_medium;
      border-color: $grey_medium;
      &:hover {
        background-color: $grey_medium !important;
        border-color: $grey_medium !important;
      }
    }
  }

  &.btn-secondary {
    background-color: $grey_light;
    border-color: $grey_light;
    color: $white;

    &:hover {
      background-color: $grey_medium;
      border-color: $grey_medium;
    }
  }

  &.btn-outline-primary {
    border-color: $grey_medium;
    color: $grey_light;

    &:active {
      background-color: $grey_medium !important;
      border-color: $grey_medium !important;
    }

    &:hover {
      color: $grey_base;
      background-color: $white;
      border-color: $white;
    }
  }
}

.buttonGroupContainer {
  position: fixed;
  top: 46px;
  width: 100%;
  background-color: $white;
  z-index: 10;
  padding: 3rem 1rem 1rem 1rem;
  background-color: $grey_dark;
  // margin-left: -1rem;
  // margin-right: -1rem;
}

.tickets-nav-button{
  color: white;
  &:hover{
    color:black;
    text-decoration: none;
  }
}

.btn-group {
  display: block;
  margin: 0 auto;
  text-align: center;

  .btn {
    text-transform: lowercase;
    font-variant: small-caps;
    font-size: 1rem;
    border-radius: 1rem;
    height: 2rem;
    line-height: 1rem;
    transition: all .3s;
    border: none !important;
  }

  .btn-primary {
    background-color: $grey_medium !important;

    &.disabled {
      background-color: $grey_medium_dark !important;
      opacity: 1 !important;

      &:hover {
        background-color: $grey_medium_dark !important;
      }
    }
  }
}

.paymentButtonGroupContainer {
  width: 100%;
  margin: .5rem 0;

  .previousCard,
  .creditCard {
    position: relative;
    top: 2px;
  }

  .applePay {
    font-size: 1.2rem;
    margin-right: -.2rem;
  }

  .applePay,
  .googlePay,
  .msPay,
  .linkPay {
    position: relative;
    top: 1px;
  }
}

#payment-request-element {
  margin: 1rem 0;
}
