.ArtistList {
  background-color: $grey_dark;
  margin-left: -1rem;
  margin-right: -1rem;
  max-width: 900px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}



.card {

  &.ArtistTile {

    .cardImage {
      height: 250px;
      background-position: top;
      background-size: cover;
      background-color: $black;
    }
    .card-footer {
      height: auto;
      background-color: $black;

      div.description {
        color: $grey_medium;
      }
    }
  }
}
