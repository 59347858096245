.BloxHomeRow {
  height: 100%;
  background-color: $black;
  // margin-left: -1rem;
  // margin-right: -1rem;
  text-align: center;
  position: relative;

  > .col {
    height: 100%;
  }

  .HomeContent {
    position: absolute;
    z-index: 1;
    height: 10rem;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    top: 35%;
    margin-top: -5rem;

    h1 {
      color: transparent;
      font-weight: 400;
      text-align: center;
      line-height: 1;
      span {

      }
    }
  }

  svg, canvas {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  a.btn {
    display: inline-block;
    margin: 1rem auto;
    width: auto;
    text-transform: uppercase;
    font-weight: bold;
    color: $grey_base !important;
    background-color: $grey_very_light !important;

    &:hover {
      color: $grey_dark !important;
      background-color: $white !important;
    }
  }
}

.crossword-background {
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

  * {
    box-sizing: border-box;
    position: relative;
    cursor: crosshair;
  }

  html {
    cursor: crosshair;
    height: 100%;
    background: #000;
    color: #fff;
  }

  body {
    display: flex;
    place-items: center;
  }

  body {
    min-height: 100%;
    display: grid;
    place-items: center;
  }

  .text-grid {
    cursor: crosshair;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    --chars: 11;
    text-transform: uppercase;
    font-size: calc(70vmin / var(--chars));
    display: grid;
    grid-template-columns: repeat(var(--chars), 1.2em);
  }

  html {
    background: rgb(0, 0, 0);
  }
  .char {
    --d: max(0.25, var(--distance, 0));
    opacity: var(--d);
    display: inline-grid;
    place-items: center;
    transform: scale(calc(var(--d) * 1.4));
    color: rgb(
      calc(205 * var(--d)),
      calc(55 * (1 - var(--d))),
      calc(140 + (40 * var(--d)))
    );
    transition: color 0.2s linear;
  } 
}

.video-background {
  // background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // pointer-events: none;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}

.a-social-media{
  color: #bababa;

  &:hover{
    color: #5b5b5b
  }
}

.p-event-purchase{
  text-align: center;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
}